// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { Typography } from '@mui/material'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Components
import UserDropdown from 'src/layouts/components/UserDropdown'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'

// ** i18n
import { useTranslation } from 'react-i18next'

const AppBarContent = props => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props
  const { i18n } = useTranslation()

  const languages = [
    { key: 'en', label: 'EN' },
    { key: 'pt', label: 'PT' },
    { key: 'fr', label: 'FR' },
    { key: 'es', label: 'ES' }
  ]

  const handleLanguageChange = lang => {
    i18n.changeLanguage(lang)
    saveSettings({ ...settings, direction: lang === 'ar' ? 'rtl' : 'ltr' })
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 6,
        py: 2
      }}
    >
      <Box className='actions-left' sx={{ display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton color='inherit' onClick={toggleNavVisibility}>
            <Icon fontSize='1.5rem' icon='tabler:menu-2' />
          </IconButton>
        ) : null}
        <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', gap: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {languages.map(({ key, label }, index) => (
              <Box key={key} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  component='span'
                  onClick={() => handleLanguageChange(key)}
                  sx={{
                    cursor: 'pointer',
                    fontSize: '0.875rem',
                    color: 'text.primary',
                    opacity: i18n.language === key ? 1 : 0.5,
                    fontWeight: i18n.language === key ? 500 : 400,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      opacity: 0.8
                    }
                  }}
                >
                  {label}
                </Typography>
                {index < languages.length - 1 && (
                  <Typography
                    component='span'
                    sx={{
                      mx: 1,
                      color: 'text.primary',
                      opacity: 0.3,
                      fontSize: '0.875rem'
                    }}
                  >
                    |
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
          <ModeToggler settings={settings} saveSettings={saveSettings} />
        </Box>
      </Box>
      <Box className='actions-right'>
        <UserDropdown settings={settings} saveSettings={saveSettings} />
      </Box>
    </Box>
  )
}

export default AppBarContent
