import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

i18n
  // Enable backend
  .use(Backend)
  // Enable automatic language detection
  .use(LanguageDetector)
  // Enable react-i18next
  .use(initReactI18next)
  .init({
    // Default language
    fallbackLng: 'en',
    // Debug mode
    debug: process.env.NODE_ENV === 'development',
    // Supported languages
    supportedLngs: ['en', 'es', 'fr', 'pt'],
    // Namespaces
    ns: ['common'],
    defaultNS: 'common',
    // Backend configuration
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    // Detection options
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    // Interpolation configuration
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
