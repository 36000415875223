/* eslint-disable react-hooks/exhaustive-deps */
// ** React Imports
import { useState, Fragment, useEffect, useRef, useCallback } from 'react'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'react-i18next'

// ** MUI Imports
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Components
import PerfectScrollbarComponent from 'react-perfect-scrollbar'

// ** Custom Components Imports
import CustomChip from 'src/@core/components/mui/chip'
import CustomAvatar from 'src/@core/components/mui/avatar'
import WorkerAvatar from 'src/components/shared/WorkerAvatar'
import CustomScrollbar from 'src/components/shared/CustomScrollbar'

// ** Util Import
import { getInitials } from 'src/@core/utils/get-initials'
import Link from 'next/link'

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    maxWidth: 580,
    minWidth: 400,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(4.5),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0,
    '& .MuiMenuItem-root': {
      margin: 0,
      borderRadius: 0,
      padding: theme.spacing(4, 6),
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    }
  }
}))

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  maxHeight: 349
})

// ** Styled Avatar component
const Avatar = styled(CustomAvatar)({
  width: 38,
  height: 38,
  fontSize: '1.125rem'
})

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  flex: '1 1 100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75)
}))

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)({
  flex: '1 1 100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
})

const ScrollWrapper = ({ children, hidden }) => {
  if (hidden) {
    return <CustomScrollbar maxHeight={349}>{children}</CustomScrollbar>
  } else {
    return <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>
  }
}

const NotificationDropdown = props => {
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [prevNotificationCount, setPrevNotificationCount] = useState(0)
  const audioRef = useRef(null)
  const [isMuted, setIsMuted] = useState(false)

  // ** Hooks
  const { data: session } = useSession()
  const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))
  const { t } = useTranslation()

  // ** Vars
  const { direction } = settings

  const flashTitle = newCount => {
    const originalTitle = document.title
    let flashInterval
    let isFlashing = false

    const flash = () => {
      if (isFlashing) {
        document.title = `(${newCount}) New Notification${newCount > 1 ? 's' : ''}`
      } else {
        document.title = originalTitle
      }
      isFlashing = !isFlashing
    }

    flashInterval = setInterval(flash, 1000) // Flash every second

    // Stop flashing after 5 seconds
    setTimeout(() => {
      clearInterval(flashInterval)
      document.title = originalTitle
    }, 5000)
  }

  useEffect(() => {
    if (session) {
      audioRef.current = new Audio('/audio/notif.wav')
      fetchNotifications()
      fetchMuteStatus()
      const intervalId = setInterval(fetchNotifications, 6000) // 60000 ms = 1 minute

      return () => clearInterval(intervalId)
    }
  }, [session]) // Dependency array, effect runs when session changes

  useEffect(() => {
    const newNotificationCount = notifications.filter(n => !n.read_status).length
    if (newNotificationCount > prevNotificationCount) {
      if (!isMuted) {
        audioRef.current.play().catch(error => console.error('Error playing sound:', error))
      }
      flashTitle(newNotificationCount - prevNotificationCount)
    }
    setPrevNotificationCount(newNotificationCount)
  }, [notifications, prevNotificationCount, isMuted])

  const fetchMuteStatus = useCallback(async () => {
    if (!session?.user?.id) return
    try {
      const res = await fetch(`/api/notifications/${session.user.id}`)
      const data = await res.json()
      setIsMuted(data.isMuted)
    } catch (error) {
      console.error('Error fetching mute status:', error)
    }
  }, [session])

  const fetchNotifications = async () => {
    if (!session?.user?.id) return

    try {
      const res = await fetch(`/api/notifications/${session.user.id}`)
      const data = await res.json()
      if (Array.isArray(data.notifications)) {
        setNotifications(data.notifications)
        setIsMuted(data.isMuted)
      } else {
        console.error('Received non-array notifications:', data.notifications)
        setNotifications([])
      }
    } catch (error) {
      console.error('Error fetching notifications:', error)
      setNotifications([])
    }
  }

  const getVisibleNotifications = () => {
    const unreadNotifications = notifications.filter(n => !n.read_status)
    const readNotifications = notifications.filter(n => n.read_status)

    const unreadCount = unreadNotifications.length
    const maxVisible = Math.max(10, unreadCount)

    if (unreadCount >= maxVisible) {
      return unreadNotifications.slice(0, maxVisible)
    } else {
      const visibleReadCount = maxVisible - unreadCount

      return [...unreadNotifications, ...readNotifications.slice(0, visibleReadCount)]
    }
  }

  const handleMuteToggle = async () => {
    if (!session?.user?.id) return
    const newMuteStatus = !isMuted
    try {
      const res = await fetch(`/api/notifications/${session.user.id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ muteStatus: newMuteStatus })
      })
      if (res.ok) {
        setIsMuted(newMuteStatus)
      } else {
        console.error('Failed to update mute status')
      }
    } catch (error) {
      console.error('Error updating mute status:', error)
    }
  }

  const handleClearAll = async () => {
    if (!session?.user?.id) return

    try {
      await fetch(`/api/notifications/${session.user.id}`, {
        method: 'DELETE'
      })
      setNotifications([])
      handleDropdownClose()
    } catch (error) {
      console.error('Error clearing all notifications:', error)
    }
  }

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = async () => {
    setAnchorEl(null)

    // Mark all notifications as read
    if (notifications.some(n => !n.read_status)) {
      try {
        const unreadNotificationIds = notifications.filter(n => !n.read_status).map(n => n.id)

        await Promise.all(
          unreadNotificationIds.map(id =>
            fetch(`/api/notifications/${session.user.id}`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ notificationId: id })
            })
          )
        )

        // Update local state to reflect all notifications as read
        setNotifications(prevNotifications => prevNotifications.map(n => ({ ...n, read_status: true })))
      } catch (error) {
        console.error('Error marking all notifications as read:', error)
      }
    }
  }

  const handleMarkAsRead = async notificationId => {
    if (!session?.user?.id) return

    try {
      await fetch(`/api/notifications/${session.user.id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ notificationId })
      })

      setNotifications(prevNotifications =>
        prevNotifications.map(n => (n.id === notificationId ? { ...n, read_status: true } : n))
      )
    } catch (error) {
      console.error('Error marking notification as read:', error)
    }
  }

  const getNotificationMessage = (message, type) => {
    // For custom activities, parse JSON message
    if (type === 'custom_activity') {
      try {
        const { title } = JSON.parse(message)
        return title
      } catch (e) {
        return message
      }
    }

    // Check if the message is already a translation key
    if (message.startsWith('notifications.')) {
      return t(message)
    }

    // Handle workload notifications
    if (type === 'high_general_workload' || type === 'workload_normalized') {
      const percentage = message.match(/\d+/)?.[0]
      const key =
        type === 'high_general_workload'
          ? 'notifications.titles.system_workload_high'
          : 'notifications.titles.system_workload_normal'
      return t(key, { percentage })
    }

    // Handle project status change notifications
    if (type === 'project_status_changed') {
      // Extract status from message, handling both "(status}}" and "status" formats
      const statusMatch = message.match(/(?:"([^"]*)"|\(([^)]*)\})/)?.[1] || message.split(' ').pop()
      if (statusMatch) {
        const cleanStatus = statusMatch.replace(/[{}()]/g, '').trim()
        return t('notifications.titles.project_status_changed', {
          status: t(`projects.status.${cleanStatus.toLowerCase().replace(/ /g, '')}`)
        })
      }
    }

    // Map notification types to translation keys
    const typeToKey = {
      system_maintenance: 'notifications.titles.server_maintenance',
      new_project_assigned: 'notifications.titles.new_project_assigned'
    }

    // Return translated message if a key exists for the type, otherwise return the original message
    return typeToKey[type] ? t(typeToKey[type]) : message
  }

  const getCustomSubtitle = (type, notification) => {
    // For custom activities, parse JSON message to get description
    if (type === 'custom_activity') {
      try {
        const { description } = JSON.parse(notification.message)
        return description
      } catch (e) {
        return ''
      }
    }

    // Map notification types to translation keys
    const typeToKey = {
      system_maintenance: 'notifications.subtitles.server_maintenance',
      new_project_assigned: 'notifications.subtitles.new_project_assigned',
      high_general_workload: 'notifications.subtitles.system_workload_high',
      workload_normalized: 'notifications.subtitles.system_workload_normal',
      project_status_changed: 'notifications.subtitles.project_status_changed'
    }

    // Return translated subtitle if a key exists for the type
    return typeToKey[type] ? t(typeToKey[type]) : ''
  }

  const RenderAvatar = ({ notification }) => {
    const { notification_type } = notification
    let icon, color

    switch (notification_type) {
      case 'project_status_changed':
        icon = 'mdi:swap-horizontal'
        color = 'success'
        break
      case 'new_project_assigned':
        icon = 'mdi:account-check-outline'
        color = 'primary'
        break
      case 'deadline_approaching':
        icon = 'mdi:calendar-clock'
        color = 'warning'
        break
      case 'system_maintenance':
        icon = 'mdi:wrench-clock'
        color = 'warning'
        break
      case 'high_general_workload':
        icon = 'mdi:speedometer'
        color = 'error'
        break
      case 'workload_normalized':
        icon = 'mdi:speedometer-slow'
        color = 'success'
        break
      case 'custom_activity':
        icon = 'mdi:bell-badge'
        color = 'info'
        break
      default:
        icon = 'mdi:bell-outline'
        color = 'info'
    }

    return (
      <Avatar skin='light' color={color}>
        <Icon icon={icon} />
      </Avatar>
    )
  }

  if (!session) {
    return null // Or return a placeholder/loading state
  }

  const visibleNotifications = getVisibleNotifications()

  return (
    <Fragment>
      <IconButton color='inherit' aria-haspopup='true' onClick={handleDropdownOpen} aria-controls='customized-menu'>
        <Badge
          color='error'
          variant='dot'
          invisible={!notifications.some(n => !n.read_status)}
          sx={{
            '& .MuiBadge-badge': { top: 4, right: 4, boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}` }
          }}
        >
          <Icon icon='mdi:bell-outline' />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        sx={{
          '& .MuiMenu-paper': {
            minWidth: '600px',
            maxWidth: '580px'
          }
        }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            py: 3.5,
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
            cursor: 'default',
            userSelect: 'auto',
            backgroundColor: 'transparent !important',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant='h6'>Notifications</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                backgroundColor: '#3A3B64',
                color: '#7367F0',
                borderRadius: '4px',
                padding: '2px 8px',
                fontSize: '1rem',
                fontWeight: 'bold',
                display: 'inline-block',
                mr: 2
              }}
            >
              {`${notifications.filter(n => !n.read_status).length} New`}
            </Box>
            <Tooltip title={isMuted ? 'Unmute notifications' : 'Mute notifications'}>
              <IconButton
                onClick={handleMuteToggle}
                size='small'
                color='primary'
                aria-label='mute notifications'
                sx={{
                  p: 1,
                  ml: 1,
                  '&:hover': {
                    backgroundColor: theme => theme.palette.action.hover
                  }
                }}
              >
                <Icon icon={isMuted ? 'mdi:volume-mute' : 'mdi:volume-medium'} fontSize={24} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Clear all notifications'>
              <IconButton
                onClick={handleClearAll}
                size='small'
                color='primary'
                aria-label='clear all notifications'
                sx={{
                  p: 1,
                  '&:hover': {
                    backgroundColor: theme => theme.palette.action.hover
                  }
                }}
              >
                <Icon icon='mdi:notification-clear-all' fontSize={24} />
              </IconButton>
            </Tooltip>
          </Box>
        </MenuItem>
        <ScrollWrapper hidden={hidden}>
          {visibleNotifications.length > 0 ? (
            visibleNotifications.map(notification => (
              <MenuItem
                key={notification.id}
                onClick={() => handleMarkAsRead(notification.id)}
                sx={{
                  backgroundColor: !notification.read_status ? 'rgba(0, 0, 255, 0.1)' : 'transparent',
                  '&:hover': {
                    backgroundColor: !notification.read_status
                      ? 'rgba(0, 0, 255, 0.2)'
                      : theme => theme.palette.action.hover
                  }
                }}
              >
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                  <RenderAvatar notification={notification} />
                  <Box
                    sx={{ mr: 16, ml: 2.5, flex: '1 1', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
                  >
                    <MenuItemTitle sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {getNotificationMessage(notification.message, notification.notification_type)}
                    </MenuItemTitle>
                    <MenuItemSubtitle variant='body2'>
                      {notification.project_name ? (
                        <Link href={`/project/${notification.project_id}`} passHref>
                          <Typography
                            component='a'
                            sx={{
                              color: 'primary.main',
                              textDecoration: 'none',
                              '&:hover': {
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            {notification.project_name}
                          </Typography>
                        </Link>
                      ) : (
                        getCustomSubtitle(notification.notification_type, notification)
                      )}
                    </MenuItemSubtitle>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minWidth: '80px' }}>
                    <Typography variant='body2' sx={{ color: 'text', textAlign: 'right' }}>
                      {new Date(notification.timestamp).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      })}
                    </Typography>
                    <Typography variant='body2' sx={{ color: 'text.disabled', textAlign: 'right' }}>
                      {new Date(notification.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            ))
          ) : (
            <MenuItem
              sx={{
                py: 3.5,
                textAlign: 'center',
                justifyContent: 'center',
                backgroundColor: 'transparent !important'
              }}
            >
              <Typography variant='body2' color='text.secondary'>
                No notifications
              </Typography>
            </MenuItem>
          )}
        </ScrollWrapper>

        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            borderBottom: 0,
            cursor: 'default',
            userSelect: 'auto',
            backgroundColor: 'transparent !important',
            borderTop: theme => `1px solid ${theme.palette.divider}`
          }}
        ></MenuItem>
      </Menu>
    </Fragment>
  )
}

export default NotificationDropdown
